.App {
  text-align: center;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  width: 100px;
  background-color: #009f4f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150% !important;
  left:50%;
  margin-top: 20px;
  margin-left: -50px;
  font-size: 12px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #009f4f transparent;
}

@media only screen and (max-width: 1366px) {
  .tooltiptext {
    left: 58% !important;
  }
}

@media only screen and (max-width: 1280px) {
  .tooltiptext {
    left: 59% !important;
  }
}

.progress {
  position: relative;
  margin-bottom: 20px; 
}

.progress_bar {
  position: absolute;
  background: green;
  height: 4px;
  width: 0%;
  top: 50%;
  left: 1%;
}

.step {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-top:4px;
  text-align: center;
  background-color: lightgray;
  font-size: 14px;    
  color:white;
  position: relative;
  border:1px solid white;
  z-index: 2;
}

.step.active {
  border: 1px solid #009f4f;
  background-color: white;
  color: #fff;
}

.step.completed {
  background-color: #009f4f;
  color: #fff;
}

